import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../../../../contexts/AuthContext";
// Links
import DiscoverLink from "../../../Links/DiscoverLink";
import HelpLink from "../../../Links/HelpLink";
import InviteLandlordsLink from "../../../Links/InviteLandlords";
import LandlordDashboardLink from "../../../Links/LandlordDashboardLink";
import LogoutLink from "../../../Links/LogoutLink";
import MyAccountLink from "../../../Links/MyAccountLink";
import MyBookingsLink from "../../../Links/MyBookingsLink";
import ShortlistLink from "../../../Links/ShortlistLink";

const LoggedInUserMenu = ({
  currentUrl,
  lang,
  t,
  url,
  user,
  analyticsEvent,
}) => {
  const { userActionsCount } = useContext(AuthContext);
  const accountActions = userActionsCount?.accountActions;
  const bookingActions = userActionsCount?.bookingActions;

  return (
    <Fragment>
      <div className="AppHeaderDesktopUserMenu--large">
        <ul className="AppHeaderDesktopUserMenu-LinkList AppHeaderDesktopUserMenu-LinkList--loggedIn">
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <InviteLandlordsLink
              t={t}
              user={user}
              url={url}
              expand
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <MyAccountLink
              currentUrl={currentUrl}
              expand
              notifications={accountActions}
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>

          {user.hasTenantBookingRequests && (
            <li className="AppHeaderDesktopUserMenu-LinkList-item">
              <MyBookingsLink
                t={t}
                url={url}
                currentUrl={currentUrl}
                expand
                notifications={bookingActions}
                analyticsEvent={analyticsEvent}
              />
            </li>
          )}

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <LogoutLink
              t={t}
              analyticsEvent={analyticsEvent}
              lang={lang}
              url={url}
              currentUrl={currentUrl}
            />
          </li>
        </ul>
      </div>

      <div className="AppHeaderDesktopUserMenu--medium">
        <ul className="AppHeaderDesktopUserMenu-LinkList AppHeaderDesktopUserMenu-LinkList--loggedIn">
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <LandlordDashboardLink
              expand
              lang={lang}
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <InviteLandlordsLink
              t={t}
              user={user}
              url={url}
              expand
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <MyAccountLink
              currentUrl={currentUrl}
              expand
              notifications={accountActions}
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>

          {user.hasTenantBookingRequests && (
            <li className="AppHeaderDesktopUserMenu-LinkList-item">
              <MyBookingsLink
                t={t}
                url={url}
                currentUrl={currentUrl}
                expand
                notifications={bookingActions}
                analyticsEvent={analyticsEvent}
              />
            </li>
          )}
        </ul>

        <hr className="AppHeaderDesktopUserMenu-separator" />

        <ul className="AppHeaderDesktopUserMenu-LinkList">
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <DiscoverLink
              t={t}
              url={url}
              lang={lang}
              currentUrl={currentUrl}
              expand
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <ShortlistLink
              currentUrl={currentUrl}
              expand
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>
        </ul>

        <hr className="AppHeaderDesktopUserMenu-separator" />

        <ul className="AppHeaderDesktopUserMenu-LinkList">
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <HelpLink t={t} url={url} currentUrl={currentUrl} lang={lang} />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <LogoutLink
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
              lang={lang}
              currentUrl={currentUrl}
            />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

LoggedInUserMenu.propTypes = {
  currentUrl: PropTypes.string,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    hasTenantBookingRequests: PropTypes.bool,
  }).isRequired,
  analyticsEvent: PropTypes.func.isRequired,
};

export default LoggedInUserMenu;
