import dayjs from "dayjs";
import * as intersection from "./intersection";

/**
 * Type may be any that can be used with the dayjs constructor.
 *
 * {@link https://day.js.org/docs/en/parse/parse}
 * @typedef DateLike
 * @memberof WunderDate
 * @type {(String|Number|Date|dayjs.dayjs)}
 */

/**
 * A Date Range Object representing a specific time period.
 * @typedef DateRange
 * @memberof WunderDate
 * @type {Object}
 * @property {DateLike} from
 * @property {DateLike} to
 */

/**
 * A unit of time specified as a string.
 *
 * Allowed values: (seconds|minutes|hours|days|weeks|months|quarters|years)
 * @typedef TimeUnit
 * @memberof WunderDate
 * @type {String}
 */

const occupation = {
  /**
   * check occupation for one month
   * @memberof WunderDate
   * @alias occupationForMonth
   * @func
   * @param {DateLike} month
   * @param {Array.<DateRange>} allBlocks
   * @return {Number} occupation %
   * @example
   * const occupation = wunderDate
   *  .occupancyOfMonth({ from: dayjs(), to: dayjs().add(1, 'months') }, [])
   *
   * console.log(occupation) // 1
   */
  ofMonth(month, allBlocks) {
    const monthStart = dayjs(month).startOf("month");
    const monthEnd = dayjs(month).endOf("month");
    const monthDays = monthEnd.date();
    const monthNum = dayjs(month).month();

    const blocks = allBlocks.map((block) => ({
      from: dayjs(block.from).startOf("day"),
      to: dayjs(block.to).endOf("day"),
    }));

    const occupationForMonthPerDay = blocks
      .map((block) => {
        let monthDay = dayjs(monthStart);
        // stores occupation in array ([ 0, 0, 0, 1, 1, 0, ... ])
        // 0 = free, 1 = blocked
        const days = [];

        while (monthDay.month() === monthNum) {
          const overlaps = intersection.collides.pointWithLine(
            monthDay.unix(),
            [block.from.unix(), block.to.unix()],
          );

          if (overlaps) {
            days.push(1);
          } else {
            days.push(0);
          }

          monthDay = dayjs(monthDay).add(1, "days");
        }

        return days;
      })
      .reduce((a, b) => {
        // if day is blocked on 'a', take it
        // if it's not blocked, check if day on 'b' is blocked and take it
        // otherwise take 0
        return a.map((value, day) => value || b[day]);
      }, Array(monthDays).fill(0)); // prefill a not-blocked month

    const occupationForMonth = occupationForMonthPerDay.reduce(
      (a, b) => a + b,
      0,
    );
    const occupationPercentage = occupationForMonth / monthDays;

    // return 1 if it's higher than 1
    const _occupation = Math.min(1, occupationPercentage);
    return _occupation;
  },

  /**
   * check occupation for a date range
   * returns an object that represents the occupation % for all months in the range
   *
   * @func
   * @memberof WunderDate
   * @alias occupancyPerMonth
   * @param {DateRange} range
   * @param {DateLike} range.from the date where the range starts
   * @param {DateLike} range.to the date where the range ends
   * @param {Array<DateRange>} allBlocks
   * @param {DateLike} allBlocks.from date where block starts
   * @param {DateLike} allBlocks.to date where block ends
   * @return {Object} occupation values
   * @example
   * const occupation = wunderDate
   *  .occupancyPerMonth({ from: dayjs(), to: dayjs().add(1, 'years') }, [])
   *
   * console.log(occupation) // { '2017-01': 0, '2017-02': 0, '2017-03': 0, '2017-04': 0, ... }
   */
  ofRange(range, allBlocks) {
    const rangeStart = dayjs(range.from);
    const rangeEnd = dayjs(range.to);
    const monthCount = rangeEnd.diff(rangeStart, "months") + 1;
    const months = Array(monthCount)
      .fill(0)
      .map((_, i) => dayjs(dayjs(rangeStart).add(i, "months")));

    const perMonth = months.map((month) => {
      const occ = occupation.ofMonth(month, allBlocks);
      return {
        month,
        occupation: occ,
      };
    });

    const occupationPerMonth = {};

    perMonth.forEach((o) => {
      const month = o.month.format("YYYY-MM");
      const occupationValue = o.occupation;

      occupationPerMonth[month] = occupationValue;
    });

    return occupationPerMonth;
  },
};

export default occupation;
