import { languages } from "@wunderflats/constants";
import {
  mapLegacyRegionToRegionSlug,
  mapRegionSlugToLegacyLang,
} from "./utils/legacy-region-mapping";

// availableLanguages are the languages that the website can show text in
const availableLanguages = ["en", "de", "fr", "uk", "ru"];

class RouteNotFoundError extends Error {
  constructor(routeName, language) {
    super();
    this.name = "RouteNotFoundError";
    this.message = language
      ? `No route with the name ${routeName} for the language ${language} have been found`
      : `No route with the name ${routeName} have been found`;
  }
}

export const routes = setLangPrefix({
  about: `/:lang/about`,

  affiliateProgram: `https://wunderflats.com/content/:lang/affiliate-program`,

  blog: {
    de: "https://hub.wunderflats.com/de",
    en: "https://hub.wunderflats.com",
    fr: "https://hub.wunderflats.com/fr",
    ru: "https://hub.wunderflats.com/",
    uk: "https://hub.wunderflats.com",
  },

  book: `/:lang/book/:groupId/step/:stepNumber`,

  bookingsLobby: `/:lang/bookings`,

  requestPage: `/:lang/bookings/:stayId/:listingRequestId?/:section?`,

  bookingOffersRentalContract: `/:lang/booking-offers/:bookingOfferId/rental-contract.:ext`,

  bookingOffersRentalContractPreview: `/:lang/booking-offers/rental-contracts/preview.:ext`,

  cancellationNotesLandlord: `/:lang/cancellation-notes/bookings/:bookingId/landlord:ext`,

  createListing: `/:lang/create/:step?`,

  createListingRefugee: `/:lang/support-ukraine/:step?`,

  createImmocationListing: `/:lang/immocation/:step?`,

  dashboard: `/:lang/dashboard`,

  forgot: `/:lang/forgot`,

  gdprConfirm: `/:lang/gdpr/confirm`,

  gdprExports: `/:lang/gdpr/exports/:gdprRequestId`,

  help: `/:lang/help`,

  // these /content pages are only available in production, we don't deploy them locally
  helpCenter: {
    en: `https://www.helfendewaende.de/en/help-center`,
    de: `https://www.helfendewaende.de/help-center`,
    fr: `https://www.helfendewaende.de/en/help-center`, // We don't have a French site so the French link points to English
    ru: `https://www.helfendewaende.de/ru/help-center`,
    uk: `https://www.helfendewaende.de/ukr/help-center`,
  },

  helpCategory: `/:lang/help/category/:categoryId`,

  helpSubCategory: `/:lang/help/subcategory/:subcategoryId`,

  helpSinglePost: `/:lang/help/post/:postId`,

  home: `/:lang`,

  impressum: `/:lang/impressum`,

  inviteLandlord: {
    en: `/:lang/referral/invite`,
    ru: `/:lang/referral/invite`,
    uk: `/:lang/referral/invite`,
    fr: `/:lang/reference/inviter`,
    de: `/:lang/referral/einladen`,
  },

  invoiceBookingLandlord: `/:lang/invoices/bookings/:bookingId/landlord:ext`,

  invoiceExports: `/:lang/invoices/exports/:filename`,

  landlords: {
    en: "https://wunderflats.com/content/en/landlord",
    de: "https://wunderflats.com/content/de/vermieter",
    fr: "https://wunderflats.com/content/fr/proprietaire",
    ru: "https://wunderflats.com/content/en/landlord",
    uk: "https://wunderflats.com/content/en/landlord",
  },

  listingdetails: {
    de: `/:lang/moebliertes-apartment/:title/:id`,
    en: `/:lang/furnished-apartment/:title/:id`,
    ru: `/:lang/furnished-apartment/:title/:id`,
    uk: `/:lang/furnished-apartment/:title/:id`,
    fr: `/:lang/appartement-meuble/:title/:id`,
  },

  listingEditV2: `/:lang/dashboard/l/:listingId/edit`,
  listingCreationWizard: `/:lang/dashboard/l/:listingId/wizard`,

  listingedit: `/:lang/edit/:listingId`,

  listinglist: {
    de: `/:lang/moeblierte-wohnungen-auf-zeit/:region/:page([0-9]+)?`,
    en: `/:lang/furnished-apartments/:region/:page([0-9]+)?`,
    ru: `/:lang/furnished-apartments/:region/:page([0-9]+)?`,
    uk: `/:lang/furnished-apartments/:region/:page([0-9]+)?`,
    fr: `/:lang/appartements-meubles/:region/:page([0-9]+)?`,
  },
  categories: {
    de: `/:lang/moeblierte-wohnungen-auf-zeit/:region/:category([^/]+)/:page([0-9]+)?`,
    en: `/:lang/furnished-apartments/:region/:category([^/]+)/:page([0-9]+)?`,
    ru: `/:lang/furnished-apartments/:region/:category([^/]+)/:page([0-9]+)?`,
    uk: `/:lang/furnished-apartments/:region/:category([^/]+)/:page([0-9]+)?`,
    fr: `/:lang/appartements-meubles/:region/:category([^/]+)/:page([0-9]+)?`,
  },

  shortlist: `/:lang/wishlist`,

  listingRedirect: `/:lang/l/:listingId`,

  login: `/:lang/login/:step(password|signup|loginCode)?`,

  logout: `/:lang/logout`,

  multilistingRedirect: "/:lang/g/:groupId",

  multilistingdetails: {
    de: `/:lang/moebliertes-apartment/g/:title/:groupId`,
    en: `/:lang/furnished-apartment/g/:title/:groupId`,
    ru: `/:lang/furnished-apartment/g/:title/:groupId`,
    uk: `/:lang/furnished-apartment/g/:title/:groupId`,
    fr: `/:lang/appartement-meuble/g/:title/:groupId`,
  },

  multilistingedit: `/:lang/edit/g/:groupId`,
  multilistingeditV2: `/:lang/dashboard/g/:groupId/edit`,

  multilistingsingleedit: `/:lang/edit/g/:groupId/:listingId`,
  multilistingsingleeditV2: `/:lang/dashboard/g/:groupId/edit`, // Sublisting is specified by query-parameter `sublisting`

  permalinks: "/:lang/permalinks/listings/:listingId",

  platformRoutes: "/platform/routes/:routeName",
  platformUrls: "/platform/urls/:routeName",

  privacypolicy: `/:lang/datenschutz`,

  referralLanding: `/:lang/invite/:name/:referralId`,
  legacyReferral: `/:lang/referral/:name/:referralId`,

  requests: `/:lang/requests/:bookingRequestId/:listingRequestId`,

  signup: `/:lang/signup`,

  tos: `/:lang/tos`,
  tosUk: `/:lang/tos-uk`,

  userBackend: `/:lang/my/:section/:subSection?`,

  userBackendBookingManagement: `/:lang/my/bookings`,

  legacyUserBackendListingManagement: `/:lang/my/listings`,
  legacyBackendGroupListingRequests: `/:lang/my/listings/g/:groupId/requests/:status?`,
  legacyUserBackendListingRequests: `/:lang/my/listings/:listingId/requests/:status?`,
  legacyUserBackendGroupListingRequestDetails: `/:lang/my/listings/g/:groupId/requests/:bookingRequestId/:listingRequestId`,
  legacyBackendListingRequestDetails: `/:lang/my/listings/:listingId/requests/:bookingRequestId/:listingRequestId`,
  legacyUserBackendRequestsOverview: `/:lang/my/requests/:filter?`,
  legacyUserBackendTenantManagement: `/:lang/my/tenants`,

  extensionV2: "/:lang/extension/:bookingId/step/:stepNumber",

  contractLegacy: {
    en: "/:lang/accept-contract/:bookingOfferId",
    ru: "/:lang/accept-contract/:bookingOfferId",
    uk: "/:lang/accept-contract/:bookingOfferId",
    fr: "/:lang/accepter-contrat/:bookingOfferId",
    de: "/:lang/vertrag-akzeptieren/:bookingOfferId",
  },

  contract: "/:lang/my/requests/:listingRequestId/accept-contract",

  signedContractLandlord: `/:lang/bookings/:bookingId/signed-contract`,

  myProfile: `/:lang/account/:section?`,

  account: `/:lang/account/:section?`,

  myBookingPreferences: `/:lang/account/booking-preferences`,

  myBillingDetails: `/:lang/account/billing-details`,

  mySettings: `/:lang/account/settings`,

  components: `/:lang/components`,

  missingPhonePage: `/:lang/missing-phone`,
});

// This will replace all the :lang by specific language handlers
// ex :lang(de|en) for a general route and then :lang(de) for a specific one
function setLangPrefix(rawRoutes) {
  return Object.entries(rawRoutes).reduce((buildRoutes, [routeName, route]) => {
    if (typeof route === "string") {
      buildRoutes[routeName] = route.replace("/:lang", getLangPrefix());
    } else {
      buildRoutes[routeName] = {};
      Object.entries(route).forEach(([language, routeValue]) => {
        buildRoutes[routeName][language] = routeValue.replace(
          "/:lang",
          getLangPrefix([language]),
        );
      });
    }

    return buildRoutes;
  }, {});
}

export function getLangPrefix(supportedLanguages = availableLanguages) {
  return `/:lang(${supportedLanguages.join("|")})`;
}

/**
 * @return {String}
 */
export function getRoute(name, lang) {
  if (typeof routes[name] === "string") {
    return routes[name];
  }

  if (typeof routes[name] === "object") {
    return routes[name][lang];
  }

  if (!(name in routes)) {
    throw new RouteNotFoundError(name);
  }

  throw new RouteNotFoundError(name, lang);
}

/**
 * @return {Object}
 */
export function getRoutes(name) {
  if (typeof routes[name] === "object") {
    return routes[name];
  }

  if (typeof routes[name] === "string") {
    const langRoutes = {};
    availableLanguages.forEach((lang) => {
      langRoutes[lang] = routes[name];
    });
    return langRoutes;
  }

  throw new RouteNotFoundError(name);
}

export function getDefaultRoute(routeName) {
  if (!routes[routeName]) {
    throw new RouteNotFoundError(routeName);
  }
  return typeof routes[routeName] === "string" ? routes[routeName] : null;
}

export function legacySearchPageRoutesMapper(routeName, routeParams) {
  const legacyRouteNames = ["listinglist", "categories"];
  if (legacyRouteNames.includes(routeName) && routeParams.region) {
    if (routeParams?.lang === "de" || routeParams?.lang === "fr") {
      const region = mapRegionSlugToLegacyLang(
        routeParams.region,
        routeParams?.lang,
      );

      return {
        ...routeParams,
        region,
      };
    }

    const region = mapLegacyRegionToRegionSlug(routeParams.region);

    return {
      ...routeParams,
      region,
    };
  }

  return routeParams;
}

export const supportedHelpPageLangs = ["en", "de", "fr"];

export const getAvailableLanguages = () => availableLanguages.slice(0);
export const getSystemLanguages = () => languages.USER_PROFILE_LANGUAGE_VALUES;
