import gql from "./gql";

const factory = (http) => {
  return {
    async getShortlist() {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          {
            shortlist(name: "default") {
              items {
                addedAt
                group {
                  __typename
                  ... on PublicGroup {
                    _id
                    minBookingDuration
                    active
                    published
                    deleted
                    restrictions {
                      onlyStudentsAllowed
                    }
                    listings {
                      availableFrom
                      availableTo
                      vacancies {
                        from
                        to
                      }
                    }
                    coverImage {
                      name
                      urls {
                        large
                        medium
                        small
                        og
                        original
                        thumbnail
                      }
                    }
                    groupId
                    pictures {
                      urls {
                        large
                        medium
                        small
                        original
                      }
                    }
                    title {
                      en
                      de
                    }
                    price
                    currency
                    address {
                      districts
                      city
                      location {
                        coordinates
                      }
                    }
                    rooms
                    beds
                    accommodates
                    area
                  }
                  ... on OfflineGroup {
                    groupId
                    active
                    published
                    deleted
                    address {
                      city
                      districts
                    }
                  }
                }
              }
            }
          }
        `,
      });
      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }
      return data;
    },

    async removeGroupFromShortlist(groupId) {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          mutation removeGroupFromShortlist($groupId: String!) {
            removeGroupFromShortlist(
              input: { name: "default", groupId: $groupId }
            ) {
              ... on RemoveGroupFromShortlistPayloadSuccess {
                success
              }

              ... on RemoveGroupFromShortlistPayloadError {
                message
                code
              }
            }
          }
        `,
        variables: {
          groupId,
        },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data;
    },

    /**
     * Adds shortlist element to a shortlist
     */
    async addGroupToShortlist(groupId, addedAt) {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          mutation addGroupsToShortlist(
            $groupId: String!
            $addedAt: DateTime!
          ) {
            addGroupToShortlist(
              input: { name: "default", groupId: $groupId, addedAt: $addedAt }
            ) {
              ... on AddGroupToShortlistPayloadSuccess {
                item {
                  addedAt
                  group {
                    ... on PublicGroup {
                      groupId
                    }
                  }
                }
              }

              ... on AddGroupToShortlistPayloadError {
                message
                code
              }
            }
          }
        `,
        variables: {
          groupId,
          addedAt,
        },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data;
    },

    /**
     * Adds multiple shortlist elements to a shortlist
     * @param {ShortlistElt[]} shortlistElements
     */
    async addGroupsToShortlist(shortlistElements) {
      if (!shortlistElements || shortlistElements.length === 0) {
        return;
      }

      const { data, errors } = await http.post("/graphql", {
        query: gql`
          mutation addGroupsToShortlist(
            $shortlistElements: [AddGroupsToShortlistInputGroup!]!
          ) {
            addGroupsToShortlist(
              input: { name: "default", groups: $shortlistElements }
            ) {
              ... on AddGroupsToShortlistPayloadSuccess {
                item {
                  addedAt
                  group {
                    ... on PublicGroup {
                      groupId
                    }
                  }
                }
              }

              ... on AddGroupsToShortlistPayloadError {
                message
                code
              }
            }
          }
        `,
        variables: {
          shortlistElements,
        },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data;
    },
  };
};

export default factory;
