import dayjs from "dayjs";
import ResourceNotFoundError from "../../../../errors/resourceNotFoundError";
import date from "../../../../utils/date";
import { shouldUseMoveInDatesSuggetion } from "./utils";

export const getMoveInDateSuggestionForListing = async ({
  api,
  listingId,
  from,
  to,
}) => {
  if (!from || !to) {
    return null;
  }

  let data;
  try {
    data = await api.listings.getMoveInDateSuggestionForListing({
      listingId,
      from,
      to,
    });
  } catch (e) {
    data = {};
  }

  return data?.moveInDateSuggestion;
};

const checkIfListingRequestedBefore = ({
  bookingRequests,
  listingId,
  groupId,
  partOfGroup,
  from,
  to,
}) => {
  const notLostBRs = bookingRequests.filter((bookingRequest) => {
    return bookingRequest.status.type !== "LOST";
  });

  for (const bookingRequest of notLostBRs) {
    for (const listingRequest of bookingRequest.listingRequests) {
      const listingMatches = partOfGroup
        ? listingRequest.listing.groupId === groupId
        : listingRequest.listing._id === listingId;
      const requestDurationOverlaps =
        date.isBetween(from, listingRequest.from, to) ||
        date.isBetween(from, listingRequest.to, to);

      if (
        listingMatches &&
        requestDurationOverlaps &&
        listingRequest?.status?.type !== "TENANT_WITHDREW" &&
        listingRequest?.status?.type !== "NOT_AVAILABLE"
      ) {
        return listingRequest;
      }
    }
  }
};

const getListing = async ({ api, groupId }) => {
  const listingsData = await api.listings.getListingsByGroupIds({
    groupIds: [groupId],
  });
  const [listing] = listingsData || [];

  if (!listing || listing.deleted === true) {
    throw new ResourceNotFoundError({ resource: "listing" });
  }

  if (listing.partOfGroup) {
    const multilistingAvailableFrom = listing.listings?.sort(
      (a, b) => new Date(a.availableFrom) - new Date(b.availableFrom),
    )[0].availableFrom;
    listing.availableFrom = multilistingAvailableFrom;
  } else {
    listing.availableFrom = listing.listings[0]?.availableFrom;
  }

  return listing;
};

const getLatestListingRequest = async ({ bookingRequests }) => {
  const [latestBr] =
    bookingRequests
      ?.filter((br) => br?.status?.type === "OPEN")
      // This gets the last element from the array
      ?.slice(-1) || [];

  const [latestListingRequest] = latestBr?.listingRequests?.slice(-1) || [];
  return latestListingRequest;
};

const getOverlappingListingRequest = ({
  isSuggestedDateAvailable,
  from,
  to,
  bookingRequests,
  listing,
  groupId,
}) => {
  const overlappingRequest = checkIfListingRequestedBefore({
    from,
    to,
    bookingRequests,
    listingId: listing._id,
    groupId,
    partOfGroup: listing.partOfGroup,
  });

  let result = null;

  if (overlappingRequest) {
    // if there is suggested any date the listing is requested only if the from and to dates fall on exact dates from LR
    if (isSuggestedDateAvailable) {
      result =
        overlappingRequest &&
        overlappingRequest?.from?.slice(0, 10) === from &&
        overlappingRequest?.to?.slice(0, 10) === to
          ? overlappingRequest
          : null;
    } else {
      result = overlappingRequest;
    }
  }

  return result;
};

export default async ({
  api,
  params: { groupId },
  user,
  query: { from, to, suggestedFrom, suggestedTo },
}) => {
  const listing = await getListing({ api, groupId });

  let moveInDatesSuggestion = null;
  /** We don't show a suggestion to switch move in dates according to max allowed vacancy if
   * the listing is a refugee exclusive listing or a multilisting. It's not possible to
   * calculate if a listing is withing max vacancy if no dates are selected. (We also don't show it
   * for extension requests.)
   */
  if (
    shouldUseMoveInDatesSuggetion({
      listing,
      from,
      to,
      hasDatesSuggestedByLL: suggestedFrom || suggestedTo,
    })
  ) {
    moveInDatesSuggestion = await getMoveInDateSuggestionForListing({
      api,
      listingId: listing._id,
      from,
      to,
    });
  }

  if (!user) {
    return {
      listing,
      moveInDatesSuggestion,
      blocks: [],
    };
  }

  const bookingRequests = await api.bookingRequests.getBookingRequestsForTenant(
    user._id,
    {
      fields: ["listingRequests", "status"],
    },
  );

  const latestListingRequest = await getLatestListingRequest({
    api,
    user,
    bookingRequests,
  });

  const overlappingListingRequest = getOverlappingListingRequest({
    from: suggestedFrom || from || latestListingRequest?.from,
    to: suggestedTo || to || latestListingRequest?.to,
    isSuggestedDateAvailable: suggestedFrom || suggestedTo,
    bookingRequests,
    listing,
    groupId,
  });

  const blocks = listing.partOfGroup
    ? await api.multilistings.getBlocks(groupId).catch(() => [])
    : await api.listings
        .getBlockedDates(listing._id, {
          from: dayjs.utc().add(1, "day").format("YYYY-MM-DD"),
          to: dayjs.utc().add(5, "years").format("YYYY-MM-DD"),
        })
        .catch(() => []);

  return {
    blocks,
    isListingRequested: overlappingListingRequest,
    latestListingRequest,
    listing,
    suggestedFrom,
    suggestedTo,
    moveInDatesSuggestion,
  };
};

// Returns false because BookingWizard already handles the transitions between the steps.
// Otherwise the page is being rendered twice.
export function shouldBookingPageReload() {
  return false;
}
