import { misc } from "@wunderflats/constants";
import _isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import promiseMemo from "promise-memoize";
import qs from "qs";
import { EXPERIMENT_NAME_RANKING } from "../../../../analytics/experiments";
import seoCollections from "../../../../assets/seo-collections.json";
import RedirectError from "../../../../errors/redirectError";
import dataLayerUtils from "../../../../utils/data-layer";
import date from "../../../../utils/date";
import * as defaultListingFilter from "../../../../utils/default-listing-filter";
import {
  createBboxFromCenter,
  createCityBboxFromCenter,
  fetchSuggestions,
  getBboxStringFromArray,
} from "../../../../utils/geocodingHelpers";
import {
  mapLegacyRegionToRegionSlug,
  mapRegionSlugToLegacyLang,
} from "../../../../utils/legacy-region-mapping";
import { safeDecodeComponent, urlMaker } from "../../../../utils/url";
import { getRelatedRegions } from "./utils";

const normalizeLocation = (location) => {
  if (!location) return null;
  const decodedLocation = safeDecodeComponent(location);
  return mapLegacyRegionToRegionSlug(decodedLocation.toLowerCase());
};

const getSeoConfigForPath = (categorySlug, lang, location) => {
  const config = seoCollections.find((item) => {
    const slugParts = item.slug.split("/");
    const lastPart = slugParts[slugParts.length - 1];

    const isDistrictCategory =
      item.filterParameters?.search?.startsWith("locality.");
    if (isDistrictCategory) {
      return (
        item.slug === `${lang}/${slugParts[1]}/${location}/${categorySlug}`
      );
    }

    const matchesLang = item.slug.startsWith(`${lang}/`);
    const matchesSlug = lastPart === categorySlug;
    const matchesLocation =
      !location ||
      !item.location ||
      normalizeLocation(location) === normalizeLocation(item.location);

    return matchesLang && matchesSlug && matchesLocation;
  });

  return config;
};

const getContentMemo = promiseMemo(
  async (api, lang, region, category) => {
    let content = null;
    try {
      content = await api.regions.getContent(lang, region, category);
    } catch (err) {
      content = {};
    }
    return content;
  },
  { maxAge: 60000 },
);

const getMunicipalitiesMemo = promiseMemo(
  (api, federalState) => {
    return api.municipalities.getByFederalState(federalState);
  },
  { maxAge: 60000 },
);

const getRegionsMemo = promiseMemo(
  (api) => {
    return api.listings.getRegions();
  },
  { maxAge: 60000 },
);

const getCategoriesMemo = promiseMemo(
  (api, lang) => {
    return api.categories.getCategories({ lang });
  },
  { maxAge: 60000 },
);

const getStructuredDataByRegionSlugMemo = promiseMemo(
  (api, region) => {
    return api.listings.getStructuredDataByRegionSlug(region);
  },
  { maxAge: 1000 * 60 * 60 * 24 }, // 24 Hours
);

const getFirstSuggestion = async (toSearch, lang) => {
  let suggestions;
  try {
    ({ suggestions } = await fetchSuggestions({ text: toSearch, lang }));
    const [firstSuggestion] = suggestions;
    const { bbox, region, placeType, country } = firstSuggestion || {};
    const _bbox = bbox || createBboxFromCenter(firstSuggestion.center);

    return {
      suggestionBbox: getBboxStringFromArray(_bbox, true),
      region,
      placeType,
      country,
    };
  } catch (e) {
    console.error(
      "firstSuggestionBBox error, in ListingsPage, while searching:",
      toSearch,
      "lang:",
      lang,
      "fetched suggestions:",
      suggestions,
    );
    console.error(e);
  }
};

export default async ({
  api,
  params = {},
  query = {},
  originalUrl,
  cookies,
}) => {
  const { redirectHrefLang } = query;

  if (params.category && redirectHrefLang) {
    const categoriesInCurrentLanguage = await getCategoriesMemo(
      api,
      params.lang,
    );
    const currentCategory = categoriesInCurrentLanguage.categories.find(
      (category) => category.slug === params.category,
    );

    const categoriesInTargetLanguage = await getCategoriesMemo(
      api,
      redirectHrefLang,
    );
    const categoryInTargetLanguage = categoriesInTargetLanguage.categories.find(
      (category) => category.label === currentCategory.label,
    );

    const categoryToRedirectTo = categoryInTargetLanguage
      ? categoryInTargetLanguage.slug
      : currentCategory.slug;

    // if category doesn't exist in the chosen language
    const langToRedirectTo = categoryInTargetLanguage
      ? redirectHrefLang
      : params.lang;

    const url = urlMaker(langToRedirectTo);
    const redirectTo = url("categories", {
      region: params.region,
      category: categoryToRedirectTo,
      page: params.page,
    });

    throw new RedirectError({ redirectTo, status: 301 });
  }

  // Do not remove scoreVariant - permanent ranking A/B test
  if (!query.scoreVariant) {
    const scoreVariant = cookies?.experiments
      ? JSON.parse(cookies.experiments)?.[EXPERIMENT_NAME_RANKING]
      : "A";
    query.scoreVariant = scoreVariant;
  }

  const queryDatesCheckResult = date.checksIfQueryDatesAreValid(query);
  const [urlPath] = originalUrl.split("?");

  const regionTranslation =
    params.lang === "de" || params.lang === "fr"
      ? mapLegacyRegionToRegionSlug(params.region)
      : params.region;

  if (!queryDatesCheckResult.isValid) {
    const newQuery = {
      ...omit(query, ["from", "to"]),
      ...omit(queryDatesCheckResult, ["isValid"]),
    };
    const fromToRemoved = qs.stringify(newQuery, {
      addQueryPrefix: true,
    });
    const redirectTo = `${urlPath}${fromToRemoved || ""}`;
    throw new RedirectError({ redirectTo, status: 301 });
  }

  // fetch all categories
  const categoriesResponse = await getCategoriesMemo(api, params.lang);
  const categories = categoriesResponse?.categories || [];

  // if there's a category param in the URL, find the matching category
  let category;
  if (params.category) {
    const seoConfig = getSeoConfigForPath(
      params.category,
      params.lang,
      params.region,
    );

    category = categories.find((cat) => cat.slug === params.category);

    if (seoConfig) {
      category = {
        ...category,
        slug: params.category,
        label: seoConfig.filterParameters?.labels || category?.label || null,
        title: seoConfig.title || category?.title,
        heading1: seoConfig.heading1,
        heading2: seoConfig.heading2,
        filterParameters: seoConfig.filterParameters,
        noIndex: seoConfig.noIndex,
      };
    } else if (!category) {
      // If there is a category param in the URL, but the categry doesn't exist in our list, show error
      const error = new Error("ResourceNotFoundError");
      error.name = "ResourceNotFoundError";
      throw error;
    }

    query.labels = category.label;

    if (category?.filterParameters) {
      query = {
        ...query,
        ...category.filterParameters,
        ...(query.search ? { search: query.search } : {}),
        ...(query.bbox ? { bbox: query.bbox } : {}),
      };
    }
  }

  // end categories

  const regions = await getRegionsMemo(api);

  let regionExists = true;
  let region;
  if (!query.search && !query.bbox) {
    region = regions.items.find((region) => region.slug === regionTranslation);
    regionExists = !!region;
  }

  // Checks if the region slug in URL exists.
  // If it's not then we're throwing ResourceNotFoundError.

  if (!regionExists) {
    const error = new Error("ResourceNotFoundError");
    error.name = "ResourceNotFoundError";
    throw error;
  }

  let currentMunicipality = null;
  if (
    query.federalState &&
    query.municipality &&
    category?.label === misc.labels.CATEGORY_HOMES_FOR_UKRAINIANS
  ) {
    try {
      const municipalities = await getMunicipalitiesMemo(
        api,
        query.federalState,
      );

      currentMunicipality =
        municipalities.find(
          (municipality) => municipality.id === query.municipality,
        ) || null;
    } catch (_error) {
      // Ignore
    }
  }

  /**
   * searchedPlaceBbox is used for centering the map
   * and request the listings of a previously searched place
   */
  let searchedPlaceBbox;

  /**
   * searchBiasBbox is used for biasing the GeocodingSearch component
   * and returning the suggestions sorted by proximity to this bbox
   */
  let searchBiasBbox;

  let placeType;
  let cityOrZipCode;
  let minBookingDurationQuery = {};

  if (query.bbox) {
    // The previous place is already a bbox, we can use that for biasing the GeocodingSearch component
    searchBiasBbox = query.bbox;
  } else if (query.search) {
    // There was a previous search, we search the bbox of that place for biasing the GeocodingSearch component
    let firstSuggestion = await getFirstSuggestion(query.search, params.lang);
    let suggestionBbox = firstSuggestion?.suggestionBbox;

    cityOrZipCode = firstSuggestion?.region;
    placeType = firstSuggestion?.placeType;

    if (!suggestionBbox) {
      // the search by query.search (so by Mapbox ID) failed
      // So we repeat the search via text params.region (e.g.: /Berlin%2C%20Germany)
      firstSuggestion = await getFirstSuggestion(
        safeDecodeComponent(params.region),
        params.lang,
      );

      suggestionBbox = firstSuggestion?.suggestionBbox;
      cityOrZipCode = firstSuggestion?.region;
      placeType = firstSuggestion?.placeType;
    }

    searchBiasBbox = suggestionBbox;
    searchedPlaceBbox = suggestionBbox;
  } else if (!query.bbox && !query.search) {
    // there was no previous search, so we get the bias
    // by searching for the region slug in the URL, e.g.: /berlin
    const firstSuggestion = await getFirstSuggestion(
      safeDecodeComponent(regionTranslation),
      params.lang,
    );

    searchBiasBbox = firstSuggestion?.suggestionBbox;
    cityOrZipCode = firstSuggestion?.region;
    placeType = firstSuggestion?.placeType;

    if (regionExists) {
      // TODO this is a quick fix for solving the BUG: https://wunderflats.atlassian.net/browse/BUG-1775. But this has to change (we may think to implement a better solution, like searching adding more context to getFirstSuggestionBbox (e.g.: search for regionTranslation+'germany')
      const bboxArray = region.bbox || createCityBboxFromCenter(region.center);
      searchedPlaceBbox = getBboxStringFromArray(bboxArray);
    } else {
      searchedPlaceBbox = searchBiasBbox;
    }
  }

  if (placeType?.[0] === "postcode") {
    minBookingDurationQuery = { zipCode: cityOrZipCode };
  } else {
    minBookingDurationQuery = { city: cityOrZipCode };
  }

  const regionOrBbox = query.bbox || searchedPlaceBbox || regionTranslation;

  const listingsResultsPromise = api.listings
    .getListingsForRegion(regionOrBbox, params.page, query)
    .catch((error) => {
      /*
      If we encounter a validation error,
      this is most likely because of badly formatted query.
      It happens often with crawler coming back with out of date
      amenities for an example.
      In that case, redirect to default SRP instead of showing
      an error page
      */
      if (error.name === "ValidationError") {
        throw new RedirectError({ redirectTo: urlPath });
      } else {
        console.error(error);
      }
    });

  const promises = await Promise.allSettled([
    getStructuredDataByRegionSlugMemo(api, regionTranslation),
    getContentMemo(api, params.lang, params.region, category?.label),
    api.listings.getMinBookingDuration(minBookingDurationQuery),
  ]);
  const [structuredData, content, minBookingDuration] = (promises || []).map(
    (p) => p.value || {},
  );

  const listingResults = await listingsResultsPromise;

  const seoMetadata = category
    ? {
        title: category.title,
        heading1: category.heading1,
        heading2: category.heading2,
        noIndex: category.noIndex,
        filterParameters: category.filterParameters,
      }
    : null;

  const defaultFilters = defaultListingFilter.getFromCookies(cookies);

  return {
    regions: regions.items.map((region) => ({
      ...region,
      slug:
        params.lang === "de" || params.lang === "fr"
          ? mapRegionSlugToLegacyLang(region.slug, params.lang)
          : region.slug,
    })),
    regionDistricts: getRelatedRegions(region, regions),
    listingResults,
    structuredData,
    content,
    category,
    categories,
    seoMetadata,
    dataLayerKey: dataLayerUtils.generateDataLayerKey(),
    searchBiasBbox,
    searchedPlaceBbox,
    defaultFilters,
    minBookingDuration: minBookingDuration.minBookingDuration,
    currentMunicipality,
    regionBbox: regionOrBbox,
  };
};

export const shouldListingsBeReloaded = (prev, curr) => {
  function extractNonReloadParams({ params, query }) {
    return {
      params,
      query: omit(query, ["view", "listing"]),
    };
  }
  /**
   * We do not want to reload the entire page when the view (map, list or filters) changes.
   * We do not want to reload the page when a user highlights a listing on the map.
   * So we take both these params out of the url that we will use to check if the page
   * should reload.
   */

  return !_isEqual(extractNonReloadParams(prev), extractNonReloadParams(curr));
};
