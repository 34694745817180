/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import loadable from "@loadable/component";
import dayjs from "dayjs";
import omit from "lodash/omit";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { UIDReset } from "react-uid";
import { ExperimentContextProvider } from "../../analytics/experiments";
import TopLoadingBar from "../../components/TopLoadingBar/TopLoadingBar";
import { AuthContextProvider, useAuth } from "../../contexts/AuthContext";
import FeatureFlagContext from "../../contexts/FeatureFlagContext";
import { ShortlistContextProvider } from "../../contexts/ShortlistContext";
import { redirectErrorName } from "../../errors/redirectError";
import useToasterQueryParamsTrigger from "../../hooks/useToasterQueryParamsTrigger";
import { getRoutes } from "../../routes";
import { globalEntityType } from "../../tracker/constants";
import { setAcquisitionChannel } from "../../utils/acquisition-channel";
import { sanitizeQuery } from "../../utils/filterUtils";
import { create as createLocalizer } from "../../utils/localizer";
import sanitizeURL from "../../utils/sanitize-url";
import {
  relativeToFullyQualified,
  safeDecodeComponent,
  url,
} from "../../utils/url";
import AuthContainer from "../AuthContainer/AuthContainer";
import ErrorPage from "../error/ErrorPage/ErrorPage";
import { AnalyticsContextProvider } from "./context/AnalyticsContext";
import { ApiContextProvider } from "./context/ApiContext";
import { CurrentBboxContextProvider } from "./context/CurrentBboxContext";
import { CurrentRouteContextProvider } from "./context/CurrentRouteContext";
import { DeviceContextProvider } from "./context/DeviceContext";
import { I18nContextProvider, useI18n } from "./context/I18nContext";
import { RouterUtilsContextProvider } from "./context/RouterUtilsContext";
import { SpaPageContextProvider } from "./context/SpaPageContext";
import { TrackerProvider, useTracker } from "./context/TrackerContext";
import { getMatchedRoute } from "./getDataForPage";
import constructRoutesProps from "./Pages/spa-routes";
import "../../styles.scss";

const LocaleData = loadable.lib(
  (props) => import(`../../locales/${props.lang}.json`),
);

const DayJsLocale = loadable.lib(
  (props) => import(`dayjs/locale/${props.lang}.js`),
);

const parseDocumentCookie = (str) => {
  return str.split(";").reduce((cookies, current) => {
    const [key, value] = current.trim().split("=").map(safeDecodeComponent);
    cookies[key] = value;

    return cookies;
  }, {});
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
    };
  }

  componentDidCatch(error) {
    // If the SPA catches an error that has a redirectTo property the page will go to that url
    if (error.name === redirectErrorName) {
      return window.location.replace(sanitizeURL(error.redirectTo));
    }

    this.setState({
      error,
    });
  }

  render() {
    const error = this.state.error || this.props.error;
    if (error) {
      return <ErrorPage {...this.props} error={error} />;
    }
    return this.props.children;
  }
}

export const App = (props) => {
  const { lang, tracker } = props;
  const routes = constructRoutesProps();

  return (
    <DayJsLocale lang={lang}>
      {() => {
        dayjs.locale(lang);
        return (
          <LocaleData lang={lang}>
            {(locale) => {
              const localiser = createLocalizer(locale, lang);

              return (
                <I18nContextProvider
                  t={localiser.t}
                  lang={localiser.lang}
                  keyExists={localiser.keyExists}
                >
                  <ErrorBoundary {...props}>
                    <TrackerProvider tracker={tracker}>
                      <ApiContextProvider api={props.api}>
                        <FeatureFlagContext.Provider value={props.featureFlags}>
                          {/* UIDReset is used to ensure unique IDs generated by react-uid library stay consistent
                        when we use SSR. If we transfer to React 18, we should replace react-uid with useId hook
                        provided bt React 18. */}
                          <UIDReset>
                            <AuthContextProvider
                              user={props.user}
                              userActionsCount={props.userActionsCount}
                            >
                              <AuthContainer
                                analytics={props.analytics}
                                query={props.query}
                                url={props.url}
                              >
                                <Router {...props} routes={routes} />
                              </AuthContainer>
                            </AuthContextProvider>
                          </UIDReset>
                        </FeatureFlagContext.Provider>
                      </ApiContextProvider>
                    </TrackerProvider>
                  </ErrorBoundary>
                </I18nContextProvider>
              );
            }}
          </LocaleData>
        );
      }}
    </DayJsLocale>
  );
};

const ComponentWithPageContext = ({ Component, appProps, ...props }) => {
  const { user } = useAuth();
  const { t, lang } = useI18n(); // we need to pass it to Component because some components may need it passed as props (class components)
  const { analytics, url, isAPhone, currentPath, experiments } = appProps;
  const {
    pageData: { searchedPlaceBbox, searchBiasBbox },
  } = props;

  return (
    <SpaPageContextProvider isSpaPage>
      <DeviceContextProvider isAPhone={isAPhone}>
        <CurrentRouteContextProvider currentPath={currentPath}>
          <RouterUtilsContextProvider url={url}>
            <AnalyticsContextProvider analytics={analytics}>
              <ShortlistContextProvider
                user={user}
                shortlistData={props?.pageData?.shortlistData}
              >
                <ExperimentContextProvider experiments={experiments}>
                  <CurrentBboxContextProvider
                    bbox={searchedPlaceBbox || searchBiasBbox}
                  >
                    <Component
                      {...omit(appProps, ["query"])}
                      {...props}
                      t={t}
                      lang={lang}
                      user={user}
                    />
                  </CurrentBboxContextProvider>
                </ExperimentContextProvider>
              </ShortlistContextProvider>
            </AnalyticsContextProvider>
          </RouterUtilsContextProvider>
        </CurrentRouteContextProvider>
      </DeviceContextProvider>
    </SpaPageContextProvider>
  );
};

/* All Collection pages have different "category" parameter name but for Homes for Ukrainians collection only we don't want to redirect to the homepage on language switch. We want the user to stay on that page. 
This collection does not support French language.

This is not the cleanest & permament solution. We hope that with the introduction of a CMS tool for collection pages this can be removed. */

const LangKeyPathnameForUkrainianCollectionPage = {
  en: "homes-for-ukrainians",
  uk: "homes-for-ukrainians-uk",
  ru: "homes-for-ukrainians-ru",
  de: "unterkunfte-fur-ukrainer",
};

const isUkrainianCategoryPage = (category) =>
  category.includes(LangKeyPathnameForUkrainianCollectionPage.en) ||
  category.includes(LangKeyPathnameForUkrainianCollectionPage.de);

const constructUrlForCategories = (matchedRoute, langKey) => {
  // if collection is Homes for Ukrainians, change category param according to the language. If not, language switch will redirect user to the homepage when switching page language
  let params = matchedRoute.params;
  if (isUkrainianCategoryPage(matchedRoute.params.category)) {
    params = {
      ...params,
      category: LangKeyPathnameForUkrainianCollectionPage[langKey],
    };
  } else {
    matchedRoute.routeName = "home";
  }

  return url(
    matchedRoute.routeName,
    { ...params, lang: langKey },
    { query: matchedRoute.query },
  );
};

const Router = ({ pageData = {}, routes, ...appProps } = {}) => {
  const location = useLocation();
  const history = useHistory();
  const lastHistoryAction = useRef(null);
  history.listen(() => {
    lastHistoryAction.current = history.action;
  });

  const pathName = location.pathname;

  const firstMatchedRoute = getMatchedRoute({
    path: pathName,
    routes,
  });
  const fullUrl = `${pathName}${location.search}`;
  const prevFullUrl = useRef(fullUrl);
  const query = useMemo(() => {
    // memoizing this because flexibleDays being removed from the URL depends on it
    return location.search
      ? sanitizeQuery(qs.parse(location.search.slice(1)))
      : {};
  }, [location.search]);

  const matchedRoute = { ...firstMatchedRoute, query };
  const firstPageLoad = useRef(true);
  const [pageItems, setPageItems] = useState({
    pageData,
    matchedRoute,
  });
  const [loading, setLoading] = useState(false);
  const { api } = appProps;
  const { lang } = useI18n();
  const { user, reloadUserActionsCount } = useAuth();
  const [error, setError] = useState();
  const { tracker } = useTracker();

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    setAcquisitionChannel(query.utm_source);
  }, [query.utm_source]);

  useEffect(() => {
    tracker.configureGlobalContext([
      {
        type: globalEntityType.PAGE,
        entity: {
          language: lang,
          pageName: matchedRoute.routeName,
          url: fullUrl,
        },
      },
      {
        type: globalEntityType.USER,
        entity: user,
      },
    ]);
  }, [fullUrl, lang, matchedRoute.routeName, tracker, user]);

  useEffect(() => {
    if (Object.keys(query).length > 0 && query.flexibleDays === null) {
      const updatedQuery = { ...query };
      delete updatedQuery.flexibleDays;

      history.replace({ search: qs.stringify(updatedQuery) });
    }
  }, [history, query]);

  // This effect is ran every time the url changes
  useEffect(() => {
    (async () => {
      // page load should not run when it's opened in the tab

      const matchedRoute = { ...firstMatchedRoute, query };
      const getDataParams = {
        query,
        params: matchedRoute.params || {},
        cookies: parseDocumentCookie(document.cookie),
        originalUrl: window.location.href,
        url: appProps.url,
        isAPhone: appProps.isAPhone,
        featureFlags: appProps.featureFlags,
      };

      const prevUrlData = {
        url: prevFullUrl.current,
        params: pageItems.matchedRoute.params,
        query: pageItems.matchedRoute.query,
        routeName: pageItems.matchedRoute.routeName,
      };

      const currentUrlData = {
        url: fullUrl,
        params: matchedRoute.params,
        query,
        routeName: matchedRoute.routeName,
      };

      const shouldReload =
        prevUrlData.routeName !== currentUrlData.routeName ||
        (matchedRoute.shouldPageReload
          ? matchedRoute.shouldPageReload(prevUrlData, currentUrlData)
          : true);

      const loadData = async () => {
        setLoading(true);

        // Loading page data and js scripts
        const [pageData] = await Promise.all([
          matchedRoute.getData({
            api,
            user,
            ...getDataParams,
          }),
          matchedRoute.component.load(),
          reloadUserActionsCount(),
        ]);

        setPageItems({ pageData, matchedRoute, loadData });
        setLoading(false);
      };

      if (!firstPageLoad.current && shouldReload) {
        await loadData();
        if (lastHistoryAction.current === "PUSH") {
          window.scrollTo(0, 0);
        }
      } else {
        setPageItems((state) => {
          return { ...state, matchedRoute, location, loadData };
        });
      }
    })().catch(setError);
    firstPageLoad.current = false;
    prevFullUrl.current = fullUrl;

    // This setup for useEffect is by design because this should only run the the url changes
    // The potential issues with deps not being there doesn't effect this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullUrl]);

  // The page is only visible to the user when pageItems.matchedRoute.component changes
  // so its important to call trackPageView when this happens
  useEffect(() => {
    // We cannot rely on pageItems?.matchedRoute?.routeName because the url changes before
    // the new page is loaded, hence the old routeName gets sent
    // Trying to decipher the page name from the url is our best bet for now
    const pageName = pathName.split("/")?.[2] || "Home";

    tracker.events.trackPageView({
      pageName,
    });
    // This setup for useEffect is by design because this should only run the the url changes
    // The potential issues with deps not being there doesn't effect this hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, tracker]);

  // If URL contains a hash, we will try to find an element with
  // that ID and scroll to it:
  const HASH_SCROLL_MAX_WAIT_TIME_MS = 2000;
  useEffect(() => {
    if (!pageItems?.matchedRoute?.component) {
      return;
    }

    if (!("MutationObserver" in window)) {
      return;
    }

    // We will use a MutationObserver to wait for the element to appear in the DOM:
    let observer;
    // We will wait for HASH_SCROLL_MAX_WAIT_TIME_MS. If we haven't found
    // the element until then, we'll stop observing:
    let timeoutId;

    const cleanup = () => {
      if (observer) {
        observer.disconnect();
      }
      clearTimeout(timeoutId);
    };

    if (location.hash) {
      const elementId = window.location.hash.slice(1);
      if (elementId) {
        observer = new MutationObserver(() => {
          const element = document.getElementById(elementId);

          if (element) {
            element.scrollIntoView({
              inline: "nearest",
              block: "start",
              behavior: "auto",
            });
            cleanup();
          }
        });
        timeoutId = setTimeout(() => {
          observer.disconnect();
        }, HASH_SCROLL_MAX_WAIT_TIME_MS);
      }

      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      cleanup();
    };
  }, [pageItems?.matchedRoute?.component, location.hash]);

  useToasterQueryParamsTrigger({ url });

  // no matched route was found, show error page
  if (!matchedRoute) {
    const error = new Error("ResourceNotFoundError");
    return <ErrorPage {...appProps} error={error} />;
  }

  const currentUrl = relativeToFullyQualified(
    url(matchedRoute.routeName, matchedRoute.params, {
      query: matchedRoute.query,
    }),
  );
  const translationUrls = Object.keys(getRoutes(matchedRoute.routeName)).reduce(
    (acc, langKey) => {
      const defaultPageUrl = url(
        matchedRoute.routeName,
        { ...matchedRoute.params, lang: langKey },
        { query: matchedRoute.query },
      );

      const determinePageUrl =
        matchedRoute.routeName === "categories"
          ? constructUrlForCategories(matchedRoute, langKey)
          : defaultPageUrl;

      const nextUrl = relativeToFullyQualified(determinePageUrl);
      acc[langKey] = nextUrl;
      return acc;
    },
    {},
  );
  return (
    <React.Fragment>
      <TopLoadingBar firstPageLoad={firstPageLoad.current} loading={loading} />
      <ComponentWithPageContext
        reloadData={pageItems.loadData}
        pageData={pageItems.pageData}
        getData={pageItems.matchedRoute.getData}
        params={pageItems.matchedRoute.params}
        query={pageItems.matchedRoute.query}
        path={pageItems.matchedRoute.path}
        Component={pageItems.matchedRoute.component}
        currentUrl={currentUrl}
        translationUrls={translationUrls}
        history={history}
        appProps={{ ...appProps, currentPath: pageItems.matchedRoute.path }}
        location={location}
        routerLoading={false}
      />
    </React.Fragment>
  );
};

export { default as constructRoutesProps } from "./Pages/spa-routes";
